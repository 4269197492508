var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    {
      staticClass:
        "col-md-6 d-flex align-items-center justify-content-center min-vh-100"
    },
    [
      _c(
        "CRow",
        { staticClass: "justify-content-center w-100" },
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCardGroup",
                [
                  _c(
                    "CCard",
                    { staticClass: "p-4" },
                    [
                      _c(
                        "CCardBody",
                        [
                          _c(
                            "CRow",
                            [
                              _c("CCol", { staticClass: "col-8 offset-2" }, [
                                _c("img", {
                                  attrs: { src: "img/logo_ocw.svg" }
                                })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "CForm",
                            [
                              _c("h4", { staticClass: "text-center py-4" }, [
                                _vm._v("ログイン")
                              ]),
                              _c("CInput", {
                                attrs: {
                                  placeholder: "メールアドレス",
                                  type: "text",
                                  autocomplete: "username email"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-user" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              }),
                              _c("CInput", {
                                attrs: {
                                  placeholder: "パスワード",
                                  type: "password",
                                  autocomplete: "curent-password"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-content",
                                    fn: function() {
                                      return [
                                        _c("CIcon", {
                                          attrs: { name: "cil-lock-locked" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _vm.showError
                                ? _c("p", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      " メールアドレスまたはパスワードが間違っています "
                                    )
                                  ])
                                : _vm._e(),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "col-md-8 offset-md-2 py-4"
                                    },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "px-4 w-100",
                                          attrs: { color: "info" },
                                          on: { click: _vm.signIn }
                                        },
                                        [_vm._v("ログイン")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <CContainer class="col-md-6 d-flex align-items-center justify-content-center min-vh-100">
    <CRow class="justify-content-center w-100">
      <CCol md="12">
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CRow>
                <CCol class="col-8 offset-2">
                  <img src="img/logo_ocw.svg" />
                </CCol>
              </CRow>
              <CForm>
                <h4 class="text-center py-4">ログイン</h4>
                <CInput
                  placeholder="メールアドレス"
                  type="text"
                  autocomplete="username email"
                  v-model="username"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  placeholder="パスワード"
                  type="password"
                  autocomplete="curent-password"
                  v-model="password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <p v-if="showError" class="text-danger">
                  メールアドレスまたはパスワードが間違っています
                </p>
                <CRow>
                  <CCol class="col-md-8 offset-md-2 py-4">
                    <CButton @click="signIn" color="info" class="px-4 w-100">ログイン</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import firebase from '@firebase/app';

export default {
  name: "Signin",
  data: function() {
    return {
      username: "",
      password: "",
      showError: false
    };
  },
  methods: {
    signIn: function() {
      this.showError = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.username, this.password)
        .then(
          user => {
            window.location.href = "/";
          },
          function(err) {
            this.showError = true;
          }.bind(this)
        );
    }
  }
};
</script>
